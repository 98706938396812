/* eslint-disable no-nested-ternary */
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import { ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../../components/core/Header/Header';
import Icon from '../../../components/core/Icon/Icon';
import { IconImage } from '../../../components/core/Icon/IconConfig';
import DataSourceIcon from '../../../components/features/connections/DataSourceIcon/DataSourceIcon';
import PageLayout from '../../../components/shared/layouts/PageLayout/PageLayout';
import {
  useGetSourceSystemConfigAttributes,
  useGetSourceSystemType,
} from '../../../lib/api-client/sources/SourceData';
import {
  ConfigAttribute,
  ConfigStep,
  SourceSystemType,
} from '../../../lib/api-client/sources/model/SourceTypes';
import { SourceSystemConfigAttribute } from '../../../lib/api-client/sources/sources.model';
import { useCurrentDataSource } from '../../sources/context/CurrentDataSourceContext';
import { ConfigAttributeForm, ConfigAttributeFormContext } from './ConfigAttributeForm';
import MarkdownRenderer from './MarkdownRenderer';

function CustomTab({
  isEditing,
  isSelected,
  pageConfig,
  configAttributesData,
  children,
  tabIndex,
  selectedValueItem,
  isLastTab,
  tabSaved,
  ...props
}: {
  isEditing: boolean;
  isSelected: boolean;
  pageConfig: {
    step: ConfigStep;
    attributes: ConfigAttribute[];
  };
  configAttributesData: SourceSystemConfigAttribute[];
  tabIndex: number;
  selectedValueItem: string;
  isLastTab: boolean;
  tabSaved: boolean[];
  children: ReactNode;
}) {
  const requiredState = pageConfig.attributes
    .filter(
      (config) =>
        config.required === true &&
        (config.conditions.length === 0 ||
          config.conditions.some((item) => item.value === selectedValueItem))
    )
    .map((item) => item.name);

  const allRequiredHaveValues = requiredState.every((req) => {
    const found = configAttributesData.find((c) => c.name === req);
    if (found) {
      return found.value != null;
    }
    return false;
  });

  const badgeState = isEditing || (!isEditing && tabSaved[tabIndex] && allRequiredHaveValues);

  const isClickable = isSelected || badgeState;

  return (
    <>
      <Tab
        _selected={{
          border: '2px',
          borderColor: 'success',
          bgColor: 'white',
        }}
        pt={1}
        pb={1}
        pl={1}
        pr={2}
        fontSize="14"
        fontWeight="400"
        color={isSelected ? 'gray.800' : badgeState ? 'white' : 'gray.800'}
        border={isSelected || badgeState ? '2px' : 'none'}
        borderColor={isSelected || badgeState ? 'success' : 'none'}
        bgColor={badgeState ? 'success' : 'gray.200'}
        borderRadius="26px"
        cursor={isClickable ? 'pointer' : 'not-allowed'}
        opacity={isClickable ? 1 : 0.5}
        pointerEvents={isClickable ? 'auto' : 'none'}
        {...props}
      >
        <Icon
          iconImage={IconImage.success}
          color={isSelected ? 'gray.400' : badgeState ? 'white' : 'gray.400'}
        />
        {children}
      </Tab>
      {!isLastTab && (
        <Box border="2px" w="18px" borderColor={badgeState ? 'success' : 'gray.300'}>
          {' '}
        </Box>
      )}
    </>
  );
}

type StepConfigAttributeMap = Record<string, ConfigAttribute[]>;
type PageConfig = { step: ConfigStep; attributes: ConfigAttribute[] }[];

function toPageConfig(sourceSystemType: SourceSystemType): PageConfig {
  const stepAttributeMap = (sourceSystemType.configAttributes ?? []).reduce<StepConfigAttributeMap>(
    (previous, current) => {
      if (current.configStep in previous) {
        previous[current.configStep].push(current);
      } else {
        // eslint-disable-next-line no-param-reassign
        previous[current.configStep] = [current];
      }
      return previous;
    },
    {}
  );

  return (sourceSystemType.configSteps ?? []).map((step) => ({
    step,
    attributes: stepAttributeMap[step.name] ?? [],
  }));
}
type SubmittingMessage = { success: boolean; message: string } | null;

function ConnectionConfigurationPage() {
  const { dataSource } = useCurrentDataSource();
  const isEditing = dataSource.configured ?? false;
  const { data: sourceTypeData, loading } = useGetSourceSystemType(dataSource.sourceSystem ?? '');
  const { data: configAttributesData, refetch: refetchConfigAttributesData } =
    useGetSourceSystemConfigAttributes(dataSource.id);
  const [tabIndex, setTabIndex] = useState(0);
  const [tabSaved, setTabSaved] = useState<boolean[]>([]);
  const navigate = useNavigate();

  const [isSubmittingMessage, setIsSubmittingMessage] = useState<SubmittingMessage>(null);
  const [selectedValue, setSelectedValue] = useState('');
  const [selectRefetch, setSelectRefetch] = useState(0);

  if (sourceTypeData == null || configAttributesData == null) {
    // TODO some sort of loading state maybe?
    return null;
  }

  const pageConfigs = toPageConfig(sourceTypeData);

  const handleNext = async () => {
    await refetchConfigAttributesData();
    setIsSubmittingMessage(null);
    setTabSaved((prev) => {
      const temp = [...prev];
      temp[tabIndex] = true;
      return temp;
    });

    if (tabIndex === pageConfigs.length - 1) {
      navigate(`/sources/${dataSource.id}/manage`);
    } else {
      setSelectRefetch(tabIndex + 1);
      setTabIndex((prevIndex) => Math.min(pageConfigs.length - 1, prevIndex + 1));
    }
  };

  const handleTabChange = async (index: number) => {
    await refetchConfigAttributesData();
    setIsSubmittingMessage(null);
    setTabIndex(index);
    setSelectRefetch(tabIndex);
  };

  return (
    <PageLayout
      loading={loading}
      pageViewEvent={{ page: 'Connection configuration' }}
      header={
        <Header
          title={`${dataSource.name} connection configuration`}
          icon={<DataSourceIcon sourceSystem={dataSource.sourceSystem} />}
          back={{
            label: 'Back to source connections dashboard',
            to: `/sources/${dataSource.id}/manage`,
          }}
        />
      }
    >
      <Tabs
        variant="soft-rounded"
        borderColor="red"
        colorScheme="whiteScheme"
        index={tabIndex}
        onChange={handleTabChange}
        _disabled={{ cursor: 'not-allowed' }}
      >
        <TabList>
          {pageConfigs.map((pageConfig, index) => (
            <HStack spacing={0} key={pageConfig.step.name}>
              <CustomTab
                pageConfig={pageConfig}
                configAttributesData={configAttributesData}
                isSelected={tabIndex === index}
                tabIndex={index}
                selectedValueItem={selectedValue}
                isEditing={isEditing}
                isLastTab={index === pageConfigs.length - 1}
                tabSaved={tabSaved}
              >
                {pageConfig.step.label}
              </CustomTab>
            </HStack>
          ))}
        </TabList>
        <TabPanels>
          {pageConfigs.map((pageConfig) => {
            const defaultConfigAttributes = pageConfig.attributes.map((configAttribute) => {
              const found = configAttributesData.find((c) => c.name === configAttribute.name);
              return (
                found ?? {
                  name: configAttribute.name,
                  value: configAttribute.dataType === 'BOOLEAN' ? false : '',
                }
              );
            });
            return (
              <TabPanel pl={-4} key={pageConfig.step.name} w="full">
                <ConfigAttributeFormContext
                  step={pageConfig.step.name}
                  configAttributesConfig={pageConfig.attributes}
                  sourceId={dataSource.id}
                  onSuccess={() => handleNext()}
                  configAttributes={defaultConfigAttributes}
                  setIsSubmittingMessage={setIsSubmittingMessage}
                >
                  {pageConfig.step.instructionText != null && (
                    <Accordion
                      key={`${pageConfig.step.name}-accordion`}
                      border="1px solid"
                      borderColor="gray.100"
                      boxShadow="sm"
                      borderRadius="6px"
                      allowToggle
                      mt={4}
                      mb={4}
                    >
                      <AccordionItem>
                        {({ isExpanded }) => (
                          <>
                            <HStack justifyContent="space-between">
                              <Text pl="16px" fontWeight="bold">
                                {pageConfig.step.instructionTitle ?? 'Instructions'}
                              </Text>
                              <AccordionButton
                                color="action"
                                fontWeight="semibold"
                                width="fit-content"
                              >
                                {isExpanded ? 'Hide details' : 'Show details'}
                                <AccordionIcon
                                  ml="2"
                                  color="action"
                                  borderRadius="50%"
                                  borderColor="action"
                                  borderWidth="thin"
                                  boxSize="16px"
                                />
                              </AccordionButton>
                            </HStack>
                            <AccordionPanel pb={4} hidden={!isExpanded}>
                              <MarkdownRenderer content={pageConfig.step.instructionText ?? ''} />
                            </AccordionPanel>
                          </>
                        )}
                      </AccordionItem>
                    </Accordion>
                  )}
                  <ConfigAttributeForm
                    isSubmittingMessage={isSubmittingMessage}
                    configAttributesConfig={pageConfig.attributes}
                    actions={pageConfig.step.actionsSupported}
                    savedConfigAttributes={configAttributesData}
                    setSelectedValue={setSelectedValue}
                    selectRefetch={selectRefetch}
                  />
                </ConfigAttributeFormContext>
              </TabPanel>
            );
          })}
        </TabPanels>
      </Tabs>
    </PageLayout>
  );
}

export default ConnectionConfigurationPage;
